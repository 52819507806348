// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import takeRight from 'lodash/takeRight'
import matches from 'lodash/matches'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources {
      edges {
        node {
          title
          position
          routeSlug
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** TableOfContents */
const TableOfContents = ({ update }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        allResources: { edges },
      } = data

      const nodes = map(edges, 'node')
      const nodes2 = filter(nodes, (o) => o.position < 9)
      const ordered = orderBy(nodes2, ['position', 'asc'])
      const nodePositions = groupBy(map(ordered, 'position'), Math.floor)

      return (
        <ul className="homepage table-of-contents as-paragraph">
          {map(nodePositions, (nodePosition) => {
            const hasChildren = nodePosition.length > 1
            const first = head(nodePosition, 1)
            const children =
              hasChildren === true
                ? takeRight(nodePosition, nodePosition.length - 1)
                : []

            return (
              <Fragment>
                {hasChildren === false && (
                  <li>
                    <Link
                      to={
                        filter(ordered, matches({ position: first }))[0]
                          .routeSlug
                      }
                      onClick={() => update(false, 'root')}
                    >
                      <strong>
                        {filter(ordered, matches({ position: first }))[0].title}
                      </strong>
                      {/* <span>{nodePosition}</span> */}
                    </Link>
                  </li>
                )}
                {hasChildren === true && (
                  <li>
                    <Link
                      style={{ display: 'block', marginBottom: '0.38rem' }}
                      to={
                        filter(ordered, matches({ position: first }))[0]
                          .routeSlug
                      }
                      onClick={() => update(false, 'root')}
                      className="block-heading"
                    >
                      <strong>
                        {filter(ordered, matches({ position: first }))[0].title}
                      </strong>
                      {/* <span>{first}</span> */}
                    </Link>
                    <ul>
                      {map(children, (child) => (
                        <li>
                          <Link
                            to={`${
                              filter(ordered, matches({ position: first }))[0]
                                .routeSlug
                            }#${
                              filter(ordered, matches({ position: child }))[0]
                                .routeSlug
                            }`}
                            onClick={() => update(false, 'root')}
                          >
                            <span>
                              {
                                filter(ordered, matches({ position: child }))[0]
                                  .title
                              }
                            </span>
                            {/* <span>{child}</span> */}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </Fragment>
            )
          })}
        </ul>
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default TableOfContents
