// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import indexImage from '../../images/banners/launch.jpg'
import website from '../../seo/website.json'
import organisation from '../../seo/organisation.json'
import geoLocation from '../../seo/geo-location.json'

import withUrl from '../../methods/withUrl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const dateModified = new Date().toISOString()
const copyrightYearEnds = new Date().getFullYear()
const indexImageWithUrl = withUrl(indexImage)

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
  * BookSchema - Implements schema.org BookSchema
  *
  * @example
    <BookSchema data={data}>
  *
  * @see {@link http://schema.org/BookSchema|Schema.org BookSchema Docs}
  */
const BookSchema = ({ data: { name, description, cover } = {} }) => {
  const imageUrl =
    typeof cover === 'undefined' ? indexImageWithUrl : withUrl(cover)

  const schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'Book',
    '@id': website.nakedUrlWithIntl,
    name,
    url: website.nakedUrlWithIntl,
    description,
    author: 'Fearn, Janet',
    bookEdition: 'Mass Market Paperback',
    bookFormat: 'https://schema.org/Paperback',
    inLanguage: 'en-US',
    isFamilyFriendly: 'true',
    copyrightYear: `${website.copyrightYearBegins}–${copyrightYearEnds}`,
    numberOfPages: '150',
    genre: 'Memoir',
    dateCreated: website.created,
    dateModified,
    potentialAction: {
      '@type': 'ReadAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'https://books.prisma.haus/books/memories-of-auroville',
        actionPlatform: [
          'http://schema.org/DesktopWebPlatform',
          'http://schema.org/AndroidPlatform',
          'http://schema.org/IOSPlatform',
        ],
      },
      expectsAcceptanceOf: [
        {
          '@type': 'Offer',
          category: 'purchase',
          price: 550,
          priceCurrency: 'INR',
          availabilityStarts: '2020-01-01',
          availabilityEnds: '2050-06-30',
          eligibleRegion: 'http://en.wikipedia.org/wiki/Earth',
        },
        {
          '@type': 'Offer',
          category: 'nologinrequired',
          availabilityStarts: '2020-01-01',
          availabilityEnds: '2050-06-30',
          eligibleRegion: 'http://en.wikipedia.org/wiki/Earth',
        },
      ],
    },
    copyrightHolder: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: organisation.name,
      legalName: organisation.legalName,
      url: organisation.url,
      logo: organisation.logo,
      foundingDate: organisation.foundingDate,
      founders: organisation.founders.map((founder) => ({
        '@type': 'Person',
        name: founder,
      })),
      address: {
        name: organisation.address.locationName,
        streetAddress: organisation.address.streetAddress,
        addressLocality: organisation.address.addressLocality,
        addressRegion: organisation.address.addressRegion,
        addressCountry: organisation.address.addressCountry,
        postalCode: organisation.address.postalCode,
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: organisation.latitude,
        longitude: organisation.longitude,
      },
      telephone: organisation.telephone,
      email: organisation.email,
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: organisation.telephone,
        email: organisation.email,
      },
      sameAs: organisation.sameAs,
    },
    publisher: {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: organisation.name,
      legalName: organisation.legalName,
      url: organisation.url,
      logo: organisation.logo,
      foundingDate: organisation.foundingDate,
      founders: organisation.founders.map((founder) => ({
        '@type': 'Person',
        name: founder,
      })),
      address: {
        name: organisation.address.locationName,
        streetAddress: organisation.address.streetAddress,
        addressLocality: organisation.address.addressLocality,
        addressRegion: organisation.address.addressRegion,
        addressCountry: organisation.address.addressCountry,
        postalCode: organisation.address.postalCode,
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: organisation.latitude,
        longitude: organisation.longitude,
      },
      telephone: organisation.telephone,
      email: organisation.email,
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: organisation.telephone,
        email: organisation.email,
      },
      sameAs: organisation.sameAs,
    },
    image: {
      '@context': 'http://schema.org',
      '@type': 'ImageObject',
      contentUrl: imageUrl,
    },
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/InStock',
      price: '599',
      priceCurrency: 'INR',
    },
    contentLocation: {
      '@context': 'https://schema.org',
      '@type': 'Place',
      name: geoLocation.locationName,
      url: geoLocation.locationUrl,
      geo: {
        '@type': 'GeoCoordinates',
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      },
      address: {
        '@type': 'PostalAddress',
        name: geoLocation.locationName,
        streetAddress: geoLocation.streetAddress,
        addressLocality: geoLocation.addressLocality,
        addressRegion: geoLocation.addressRegion,
        addressCountry: geoLocation.addressCountry,
        postalCode: geoLocation.postalCode,
      },
    },
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default BookSchema
