// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { graphql } from 'gatsby'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import CoverPage from '../components/cover-page'
import '../components/cover-page/style.less'

import BookSchema from '../components/schema/book-schema'
import ProductSchema from '../components/schema/product-schema'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageSchema = {
  title: 'Homepage',
  slug: '',
  abstract:
    'This book is about the very early days of Auroville based on interviews made in 1997 with Aurovilians who lived here between 1968 and 1973. The book is authored by Janet Fearn and published by Prisma Books.',
  breadcrumbs: [{ title: 'Cover Page', slug: '' }],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     cover: file(relativePath: { eq: "cover.jpg" }) {
//       ...max3000
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="home-page" pageSchema={pageSchema} {...props}>
    <BookSchema
      data={{
        name: 'Memories of Auroville',
        description:
          'This book is about the very early days of Auroville based on interviews made in 1997 with Aurovilians who lived here between 1968 and 1973. The book is authored by Janet Fearn and published by Prisma Books.',
      }}
    />
    <ProductSchema
      data={{
        name: 'Memories of Auroville by Janet Fearn',
        description:
          'This book is about the very early days of Auroville based on interviews made in 1997 with Aurovilians who lived here between 1968 and 1973. The book is authored by Janet Fearn and published by Prisma Books.',
      }}
    />
    <CoverPage />
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
